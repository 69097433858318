import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import FormPage from './pages/FormPage';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';

function App() {
 
  // return (
  //     <section className="absolute top-0 w-full h-full section-wrapper bg-gray-900">
  //         <Routes>                                                                        
  //           <Route path="/" element={<HomePage/>}/>
  //           <Route path="/login" element={<LoginPage/>}/>
  //           <Route path="/form" element={<FormPage/>}/>
  //         </Routes>                    
  //     </section>
  // );

  return (
    <section className="absolute top-0 w-full h-full section-wrapper bg-gray-900">
        <Routes>                                                                        
          <Route path="/" element={<FormPage/>}/>
        </Routes>                    
    </section>
);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <Router>
      <App />
    </Router>
  </>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
